// Media
.slick-slider,
.slick-list,
.slick-track {
    height: 100%;
}

.slick-slide > div {
    height: 100%;
}

.slick-prev {
    left: 2% !important;
    z-index: 10;
}

.slick-next {
    left: 95% !important;
}

@media only screen and (max-width: 700px) {
    .slick-next {
        left: 94% !important;
    }
}

@media only screen and (max-width: 600px) {
    .slick-next {
        left: 93% !important;
    }
}

@media only screen and (max-width: 350px) {
    .slick-next {
        left: 91% !important;
    }
}

//each item
@media only screen and (max-width: 500px) {
    .each-item__image {
        background-size: cover !important;
    }
}
