.error-page__back-home-btn {
    padding: 1.5rem 1rem !important;
}

@media only screen and(max-width: 1400px) {
    .error-page__heading {
        font-size: 3rem !important;
    }

    .error-page__helpful-links {
        font-size: 0.95rem !important;
    }

    .error-page__image {
        width: 500px;
    }
}

@media only screen and(max-width: 1200px) {
    .error-page__heading {
        font-size: 2.8rem !important;
    }

    .error-page__image {
        width: 420px;
    }
}

@media only screen and(max-width: 992px) {
    .error-page__heading {
        font-size: 2.7rem !important;
    }

    .error-page__helpful-links {
        font-size: 0.85rem !important;
    }

    .error-page__image {
        width: 380px;
    }
}

@media only screen and(max-width: 880px) {
    .error-page__heading {
        font-size: 2.5rem !important;
    }

    .error-page__helpful-links {
        font-size: 0.75rem !important;
    }

    .error-page__back-home-btn {
        margin-bottom: 2rem !important;
        padding: 0rem 1rem !important;
    }

    .error-page__divider {
        margin-bottom: 1.6rem !important;
    }

    .error-page__image {
        width: 350px;
    }
}

@media only screen and(max-width: 850px) {
    .error-page__message-container {
        margin-bottom: 1rem !important;
    }

    .error-page__heading {
        font-size: 2.2rem !important;
    }

    .error-page__back-home-btn {
        margin-bottom: 1.9rem !important;
    }

    .error-page__divider {
        margin-bottom: 1.5rem !important;
    }
}

@media only screen and(max-width: 768px) {
    .error-page__heading {
        font-size: 2rem !important;
    }

    .error-page__text {
        font-size: 1.2rem !important;
    }

    .error-page__back-home-btn {
        margin-bottom: 1.3rem !important;
    }

    .error-page__divider {
        margin-bottom: 1rem !important;
    }
}

@media only screen and(max-width: 710px) {
    .error-page-wrapper {
        flex-direction: column;
        justify-content: center !important;
    }

    .error-page__text-center {
        text-align: center;
    }

    .error-page__back-home-btn {
        display: block !important;
        margin: 0rem auto 1.5rem !important;
    }

    .error-page__image {
        width: 300px;
        margin-top: 2rem;
    }
}

@media only screen and(max-width: 610px) {
    .error-page__heading {
        font-size: 1.7rem !important;
    }

    .error-page__image {
        width: 250px;
    }
}
