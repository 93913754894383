.terms-and-policy__header {
  background-image: url('../../../../assets/background-static.svg');
  background-repeat: no-repeat;
  background-size: cover;

  .header__active-link {
    font-weight: 500 !important;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #fff;
      position: absolute;
      bottom: 5px;
      left: 0px;
    }
  }
}

@media only screen and (max-width: 1800px) {
  .terms-and-policy__header {
    .header__nav,
    .header__page-title {
      width: unset !important;
      padding: 0px 9%;
    }
  }
}

@media only screen and (max-width: 1470px) {
  .terms-and-policy__header {
    .header__logo {
      width: 110px;
    }

    .header__nav-list {
      font-size: 15px;
    }

    .header__active-link::after {
      bottom: 0px;
    }

    .header__page-title {
      font-size: 55px;
      margin-top: 100px;
    }
  }
}

@media only screen and (max-width: 1370px) {
  .terms-and-policy__header {
    .header__page-title {
      font-size: 50px;
    }
  }
}

@media only screen and (max-width: 1270px) {
  .terms-and-policy__header {
    .header__logo {
      width: 100px;
    }

    .header__nav-list {
      font-size: 14px;
    }

    .header__page-title {
      font-size: 45px;
      margin-top: 110px;
    }
  }
}

@media only screen and (max-width: 1070px) {
  .terms-and-policy__header {
    .header__logo {
      width: 95px;
    }

    .header__nav-list {
      font-size: 13px;
      gap: 30px;
    }

    .header__page-title {
      margin-top: 115px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .terms-and-policy__header {
    .header__nav,
    .header__page-title {
      padding: 0px 5%;
    }
  }
}

@media only screen and (max-width: 650px) {
  .terms-and-policy__header {
    .header__nav {
      flex-direction: column;
      align-items: center;
    }

    .header__logo {
      width: 120px;
      margin-bottom: 30px;
    }

    .header__nav-list {
      font-size: 14px;
    }

    .header__active-link::after {
      bottom: -5px;
    }

    .header__page-title {
      font-size: 50px;
      margin-top: 90px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .terms-and-policy__header {
    .header__logo {
      width: 105px;
    }

    .header__nav-list {
      font-size: 13px;
    }

    .header__page-title {
      font-size: 45px;
      margin-top: 90px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .terms-and-policy__header {
    .header__nav-list {
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .terms-and-policy__header {
    .header__logo {
      width: 90px;
    }

    .header__nav-list {
      font-size: 12px;
      gap: 15px;
    }

    .header__page-title {
      font-size: 40px;
    }
  }
}

@media only screen and (max-width: 315px) {
  .terms-and-policy__header {
    .header__nav-list {
      text-align: center !important;
    }
  }
}
