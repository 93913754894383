@media only screen and (max-width: 1100px) {
    .open-shared-link-wrapper {
        width: 68% !important;
    }
}

@media only screen and (max-width: 940px) {
    .open-shared-link-wrapper {
        width: 73% !important;
    }
}

@media only screen and (max-width: 900px) {
    .open-shared-link-wrapper {
        width: 75% !important;
    }
}

@media only screen and (max-width: 550px) {
    .open-shared-link-wrapper {
        width: 100% !important;
    }
}
