.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: gray;
  opacity: 0.8;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 20px 22px;
  cursor: pointer;
  display: none;
}

.scroll-to-top.show {
  display: block;
}
