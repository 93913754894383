.active-link {
    text-decoration: underline;
}

@media only screen and (max-width: 1800px) {
    .privacy-policy__page {
        width: unset !important;
        padding: 0px 9%;
    }
}

@media only screen and (max-width: 1000px) {
    .privacy-policy__page {
        width: unset !important;
        padding: 0px 5%;
    }
}

@media only screen and (max-width: 500px) {
    .privacy-policy__page {
        .privacy-policy__page-title {
            font-size: 22px;
        }

        .privacy-policy__policy-title {
            font-size: 18px;
        }

        .privacy-policy__policy {
            margin-bottom: 30px;
        }

        .policy__title {
            font-size: 20px;
            padding-left: 0px;
            margin-bottom: 20px;
        }

        .policy__content {
            font-size: 17px;
        }

        .policy-list {
            padding-left: 15px;
        }
    }
}
