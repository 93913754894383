@font-face {
  font-family: "Manrope";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
}
